<template>
  <div>
    <v-row style="margin-top: 50px" v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>

    <v-form v-else v-model="valid" ref="formCofins" lazy-validation>
      <v-container grid-list-md fluid>
        <v-widget title="Novo Item na Tabela COFINS">
          <template v-slot:widget-content>
            <v-row dense>
              <v-col cols="12" sm="3">
                <v-text-field label="Código" v-model="cofins.codigo" data-cy="codigo"></v-text-field>
              </v-col>
              <v-col cols="12" sm="9">
                <v-text-field label="Descrição" v-model="cofins.descricao" :rules="validarDescricao" required data-cy="descricao"></v-text-field>
              </v-col>
            </v-row>
          </template>
          <template v-slot:widget-footer-action>
            <v-btn text @click="cancelar">{{constantes.BOTAO_CANCELAR}}</v-btn>
            <v-btn color="primary" :loading="carregando" @click="salvar" :disabled="!valid" data-cy="salvar">{{textoBtnConfirmar}}</v-btn>
          </template>
        </v-widget>
      </v-container>
    </v-form>  
  </div>
    
</template>


<script>

import {mapState, mapGetters, mapActions} from "vuex";
import constantes from '@/util/constantes';

export default {

  data: () => ({
    valid: false,
    validarDescricao: [v => !!v || "Descrição Obrigatória"],
    constantes: constantes
  }),

  computed: {

    ...mapState('cofins', {
      cofins: 'cofinsAtual',
      carregando: 'carregando'
    }),
    
    ...mapGetters('cofins', {
      estaEditando: 'estaEditando',
      textoBtnConfirmar: 'textoBtnConfirmar'
    })
  },

  methods: {
    
    async salvar() {
      if (this.$refs.formCofins.validate()) {
        try{
          await this.$store.dispatch('cofins/salvar');
          this.$router.push({ name: 'cofins' });
        }
        catch(erro){
          console.log('Houve um erro');
        }
      }
    },

    cancelar() {
      this.$store.commit('cofins/estadoInicial');
      this.$router.push({name: 'cofins'});
    }
  },

  created(){
    
    try{

      this.loading = true;
      
      let cofins = this.$store.state.cofins.cofinsAtual;
      let estaEditando = this.$router.currentRoute.path.indexOf('editar') !== -1 ? true : false;
  
      if(estaEditando && (!cofins._id || cofins._id === '')){
        this.$store.commit('setMensagemErro', 'Selecione um cofins para editar');
        this.$router.push({ name: 'cofins' });
      }
      
      if(!estaEditando){
        this.$store.commit('cofins/estadoInicial');
      }
    }
    catch(erro){
      console.log(erro);
    }
    finally{
      this.loading = false;
    }
  }
};
</script>